.bodyCurriculo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerCurriculo {
  margin-top: 120px;
  margin-bottom: 30px;
  font-family: sans-serif;
  min-width: 800px;
}

.headerCurriculo {
  display: flex;
  flex: 1;
}

.container-header {
  color: #f6f6f6;
  background-color: #001b48;
  justify-content: center;
  padding: 2em;
  flex: 1;
  border: 10px 10px 0 0;
}

.nome-candidato {
  margin-bottom: 20px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.area-contato {
  display: flex;
  justify-content: space-between;
}

.mainCurriculo {
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.headerCurriculo,
.container-header,
.div-header-foto {
  justify-content: center;
}

.left {
  padding: 1em;
  min-width: 206px;
  max-width: 206px;
  background-color: #ffffff;
}

.container-fotoPerfil {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.container-mudar-fotoPerfil {
  position: relative;
  display: inline-block; 
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}

.overlayFotoPerfil {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  border-radius: 100%;
  transition: opacity 0.3s;
}

.container-mudar-fotoPerfil:hover .overlayFotoPerfil{
  opacity: 1;
}

.fotoPerfil {
  width: 8em;
  height: 8em;
  border-radius: 100%;
}

.notificacoes {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  align-items: center;
  font-weight: 600;
}

.notificacoes > input {
  min-width: 0px;
  width: 20px;
  height: 20px;
}

hr {
  margin: 7px 0px;
}

.main-content-curriculo {
  width: 100%;
  background-color: #d9d9d9;
}

.main-content-curriculo h2{
  font-size: 1rem;
}

.curriculoAbout {
  background-color: #e8e8e8;
  width: auto;
  padding: 1.3em;
  text-align: justify;
}

.curriculoAbout > p {
  max-width: 470px;
  word-wrap: break-word;
  font-size: 14px;
}

.iconEditarModal {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.container-editar {
  display: flex;
  justify-content: space-between;
}

.textAreaSobreMim {
  width: 500px;
  height: 190px;
  font-size: 1rem;
  resize: none;
}

.sessao-inf-usuario{
  margin-bottom: 10px;
}

.hide{
  display: none;
}

.nomeCompletoCandidato{
  word-wrap: break-word;
  max-width: 500px;
}

.endereco{
  text-transform: capitalize; 
}

.situacaoIncompleto {
  color: #e21b1b;
  background-color: #eaf1f2;
  border: 1px solid #e21b1b;
}

.situacaoCompleto {
  color: #25ae88;
  background-color: #eaf1f2;
  border: 1px solid #25ae88;
}

.situacaoCompleto,
.situacaoIncompleto {
  display: flex;
  min-width: 150px;
  border-radius: 10px;
  transition: all 500ms;
  text-align: center;
  justify-content: center;
  margin: 10px 0px;
}

.situacaoCompleto:hover,
.situacaoIncompleto:hover,
.iconEditarModal:hover {
  transform: scale(105%);
}

.between{
  display: flex;
  justify-content: space-between;
}

.between > span > p {
  word-wrap: break-word;
  width: 300px;
}

.texto-opaco{
  opacity: 0.8;
}

.iconeEditarArea{
  width: 15px;
  height: 15px;
}

.chart-body {
  border-radius: 20px;
  width: 250px;
  height: 120px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px auto;
  .chart-serie {
    position: relative;
    max-height: 130px;
    width: 50px;
    border-radius: 5px;
    animation: rise 1s linear forwards;
  }
}

.chart-title {
  text-align: center;
  position: relative;
  top: -25px;
  color: #000;
}

.chart-serie label {
  text-align: center;
  position: absolute;
  bottom: -25px;
  width: 100%;
  font-size: 12px;
  color: #333;
}

@keyframes rise {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@media (max-width: 1300px) {
  .headerCurriculo {
    min-width: 220px;
  }
  .left{
    max-width: none;
  }
}

@media (max-width: 900px) {
  .containerCurriculo {
    min-width: min-content;
    width: 100%;
  }

  .container-fotoPerfil {
    background-color: #001b48;
  }

  .hidden-responsive {
    display: none;
  }

  .div-header-foto {
    display: flex;
    flex-direction: column;
  }

  .area-contato {
    flex-direction: column;
    gap: 10px;
  }

  .container-header {
    padding: 2em;
  }

  .headerCurriculo {
    width: 100vw;
    margin: auto;
    display: flex;
    flex-direction: row;
  }

  .main-content-curriculo {
    width: 100vw;
    margin: 0px auto;
  }

  .textAreaSobreMim {
    width: 80vw;
    height: 240px;
    font-size: 1rem;
  }
}

@media (max-width: 540px) {
  .headerCurriculo {
    flex-direction: column;
  }
  .chart-body {
    margin: 30px 0px;
  }
}
