#containerServicos {
    width: 100%;
    height: max-content;
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 200%;
    padding: 30px 0;
    animation: animacaoHome 300ms ease-in-out;
    background-color: #EAEBED;
}

@keyframes animacaoHome {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#servicoTitulo {
    margin: 0 auto;
    max-width: 1300px;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 30px 40px;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0 0 0 rgba(154,160,185,.05), 0 0px 50px rgba(166,173,201,0.3);
}

#servicosContent {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1300px;
    padding: 30px 40px;
}

#servicosContent p {
    text-align: justify;
    margin-bottom: 20px;
}

#servicosContent section {
    flex: 1 0 300px;
    position: relative;
    border: #018ABE solid 1px;
    padding: 30px 20px;
    border-radius: 10px;
    transition: all 300ms ease-in-out;
    background-color: rgba(166,173,201,0.1);
}

#servicosContent section:hover {
    background-color: #018ABE;
    color: #F1F1F3;
}

.iconeServico {
    width: 60px;
    padding: 5px;
    border-radius: 10px;
    margin-right: 30px;
    box-shadow: 0 0 0 rgba(154,160,185,.05), 0 0px 50px rgba(166,173,201,0.3);
    float: left;
    border-radius: 50%;
}

#btnAcaoServico {
    width: 100%;
    background-color: #001B48;
    text-decoration: none;
    color: #F1F1F3;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 300ms ease-in-out;
}

#btnAcaoServico:hover {
    background-color: #02457A;
    transform: scale(105%)
}

@media (max-width: 1000px) {

    #servicoTitulo {
       margin: 10px 20px;
    }

    #servicosContent {
       margin: 10px;
       padding: 0;
    }
}