#conteudoContienerContato {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    animation: animacaoConteudo 0.5s;
}

@keyframes animacaoConteudo {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#atencimentoIcone {
    width: 200px;
}

#cabecalhoFormulario {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#cabecalhoFormulario p {
    text-align: center;
    padding: 20px 30px;
    box-sizing: border-box;
}

#formularioContiener {
    margin: 150px 0 30px 0;
    margin-right: 3%;
    width: 100%;
    max-width: 800px;
    background-color: #FFFFFF;
    border-radius: 10px;
    z-index: 10;
    box-sizing: border-box;
}

#contatoIcone {
    width: 50px;
    padding: 20px 20px 30px 30px;
}

#formNovoLead {
    align-content: center;
    padding: 20px;
}

#formNovoLead span {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

#formNovoLead input, #formNovoLead select, #formNovoLead textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    background-color: #F1F1F3;
    padding: 10px 20px;
    margin: 5px;
    box-sizing: border-box;
    height: 50px;
}

#formNovoLead textarea {
    border-radius: 5px;
    border: none;
    background-color: #F1F1F3;
    padding: 10px 20px;
    margin: 5px;
    box-sizing: border-box;
    height: 200px;
}

#formNovoLead input:focus, #formNovoLead select:focus, #formNovoLead textarea:focus {
    outline: none;
    border: #02457A solid 1px;
}

#formNovoLead input::placeholder, #formNovoLead textarea::placeholder {
    font-weight: 300;
    font-size: 0.7rem;
    opacity: 70%;
}

#inputsLead {
    display: flex;
    flex-direction: column;
}

#inputsLead label {
    margin: 5px;
    margin-bottom: -5px;
}

#consentimentoFormulario {
    display: flex;
}

#aceitePoliticaPrivacidade {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    font-size: 0.8rem;
}

#aceitePoliticaPrivacidade #menuLink {
    margin-left: 10px;
    color: #97CAD8;
}

#btnEnviarDados {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 15px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #001B48;
    color: #F1F1F3;
    transition: all 300ms ease-in-out;
    box-sizing: border-box;
}

#btnEnviarDados:hover {
    cursor: pointer;
    background-color: #02457A;
}

/* RESPONSIVIDADE DA PÁGINA */

@media (max-width: 1000px) {

    #conteudoContienerContato {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #conteudoContiener {
        font-size: 1.2rem;
        margin: 0;
    }

    #cabecalhoLead {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #cabecalhoFormulario {
        display: flex;
        background-color: #FFFFFF;
        z-index: 10;
        margin-top: 150px;
        border-radius: 5px;
        padding: 10px 0;
        width: 90%;
    }

    #formularioContiener {
        margin-top: 20px;
        border-radius: 0;
        margin-right: 0;
    }

    #formNovoLead span {
        display: flex;
        flex-direction: column;
    }

    #formNovoLead input, #formNovoLead select, #formNovoLead textarea {
        padding: 10px;
    }

    #formNovoLead input::placeholder, #formNovoLead textarea::placeholder {
        font-family: 'Poppins', sans-serif;
        color: black;
    }

    #aceitePoliticaPrivacidade {
        margin-left: 5px;
    }
    
}

