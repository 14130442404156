main {
    margin: 0 auto;
    animation: animacaoConteudo 0.5s;
}

@keyframes animacaoConteudo {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#introducaoHomeContenier {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 50px auto;
    max-width: 1300px;
    height: 60vh;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 20px;
    box-sizing: border-box;
}

#introducaoHomeContenier span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 0;
}

#escritorioHome {
    width: 40vw;
    min-width: 200px;
}

#introducaoHomeContenier span:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

#chamacaoAcaoTitulo h1 {
    font-size: clamp(1.2rem, 1.5rem, 1.7rem);
    font-weight: bold;
    margin-bottom: 20px;
}

#apresentacaoSistemaGesttor {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 30px auto;
    height: 400px;
    max-width: 711px;
}

#funcionalidadesGesttor {
    background-color: #EAEBED;
    padding: 50px 0;
}

#funcionalidadesGesttor section {
    margin: 0 auto;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    box-sizing: border-box;
}

#funcionalidadesGesttor span {
    flex: 1 0 450px;
    text-align: justify;
    background: linear-gradient(to top, #E6F1FB 20%, transparent 100%);
    border-bottom: #78869C solid 1px;
    border-radius: 10px;
    padding: 20px;
}

#funcionalidadesGesttor img {
    background: linear-gradient(145deg, rgb(120 143 156 / 0.6) 20%, #FF6B6C 100%);
    background-size: 300%;
    border-radius: 10px;
    padding: 10px;
    float: left;
    margin: 0 15px 0 0;
    width: 100px;
}

#funcionalidadesGesttor p {
    margin: 20px 0px;
}

#funcionalidadesGesttor li {
    list-style: none;
    background-image: url(../../assets/img/confirmacaoLista.png);
    background-repeat: no-repeat;
    padding: 0 30px;
    background-position: left center;
    background-size: 20px;
    line-height: 200%;
}


@media (max-width: 1000px) {

    #introducaoHomeContenier {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: max-content;
        width: calc(100% - 10px);
        margin-top: 110px;
    }

    #introducaoHomeContenier span:nth-child(1) {
        width: 100%;
        text-align: center;
        align-items: center;
    }

    #escritorioHome {
        display: none;
    }

    #funcionalidadesGesttor section {
        margin: 10px;
    }

    #funcionalidadesGesttor span {
        flex: 1 0 300px;
        padding: 10px;
    }

    #funcionalidadesGesttor li {
        text-align: left;
    }
}