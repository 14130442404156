#containerIntroducaoHome {
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: calc(100vh - 100px);
    margin-top: 100px;
    max-width: 1300px;
}

#introducaoHomeContent {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    gap: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#introducaoHomeContent ~ span {
    flex: 1 0 200px;
}

#introducaoHomeContent img {
    width: 350px;
    padding: 50px;
    border-radius: 20px;
}

#ladoEsquerdoContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

#introducaoHomeContent h1 {
    font-size: 5rem;
}

#btnChamadaHome {
    background-color: #001B48;
    text-decoration: none;
    color: #F1F1F3;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 300ms ease-in-out;
}

#btnChamadaHome:hover {
    background-color: #02457A;
    transform: scale(105%)
}

@media (max-width: 1000px) {

    #introducaoHomeContent {
        width: calc(100% - 20px);
        padding: 10px;
    }

    #textosIntroducaoHome {
        text-align: center;
    }

    #introducaoHomeContent h1 {
        font-size: 2rem;
    }

    #imgIntroducaoHome {
        display: none;
    }
}
