#conteudoContienerPrivacidade {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    margin: 0 auto;
    animation: animacaoConteudo 0.5s;
}

@keyframes animacaoConteudo {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#conteudoContienerPrivacidade {
    display: flex;
    margin-top: 120px;
}

#tituloPoliticaPrivacidade {
    margin: 0 auto;
    background-color: #F1F1F3;
    border-radius: 10px;
    padding: 50px;
    margin: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 0 rgba(154,160,185,.05), 0 0px 50px rgba(166,173,201,0.3);
}

#conteudoContienerPrivacidade p:nth-child(1) {
    margin-top: 50px;
    text-align: justify;
    text-indent: 4rem;
    box-sizing: border-box;
}

#conteudoContienerPrivacidade p {
    margin: 30px 20px;
    text-align: justify;
    text-indent: 4rem;
    box-sizing: border-box;
}

#conteudoContienerPrivacidade h2, #conteudoContienerPrivacidade h3, #conteudoContienerPrivacidade li {
    margin: 20px;
    text-align: justify;
}

#conteudoContienerPrivacidade h2 {
    margin-top: 50px;
}

#paginaPoliticaPrivacidade li {
    margin: 0 20px;
}

