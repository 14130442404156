#modalAlertaFundo {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.2);
}

#modalFundoDados {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: max-content;
    padding: 40px 20px 20px 20px;
    background-color: #FEFCFF;
    margin: 0 20px;
    border-radius: 5px;
    animation: animacaoModalFundo 1s;
}

@keyframes animacaoModalFundo {
    0% {
        transform: translateY(-300px);
        opacity: 0;
    }
}

#modalFundoDados span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin-top: -40px;
    width: 100%;
}

#modalFundoDados span img {
    width: 80px;
    border-radius: 50%;
    animation: animacaoIconeModal 2s;
}


@keyframes animacaoIconeModal {
    0% {
        opacity: 0;
        transform: scale(200%);
    }
    100% {
        opacity: 1;
        transform: scale(100%);
    }
}

#modalFundoDados p {
    text-align: center;
    width: 90%;
    padding: 20px 0;
}

#modalFundoDados div {
    width: 90%;
}