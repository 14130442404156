.menuLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    color: #F1F1F3;
    border-radius: 5px;
    transition: color 200ms ease-in-out;
    position: relative;
    cursor: pointer;
}

.menuLink:hover {
    color: #97CAD8;
}

.menuLink:before {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    visibility: hidden;
    background-color: #D6EBEE;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

.menuLink:hover::before {
    visibility: visible;
    width: 100%;
}

@media (max-width: 1000px) {
     header .menuLink {
        align-items: center;
        justify-content: center;
        background-color: #97CAD8;
        padding: 10px 0;
        box-sizing: border-box;
        color: #001B48;
        font-size: 1.2rem;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
   }

   header .menuLink:hover {
        color: #001B48;
   }
}