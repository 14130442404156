.containerLinks {
    margin: 150px auto;
    max-width: 540px;
}

.containerLinks a {
    text-decoration: none;
    color: black;
}

.containerLinks h1 {
    margin-bottom: 8px;
}

.containerLinks p {
    text-align: justify;
    margin-right: 10px;
    padding-right: 10px;
}

.containerLinks section {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    border: solid 1px #02457A;
    padding: 10px;
    box-sizing: border-box;
    margin: 20px;
    transition: all 300ms;
}

.containerLinks section:hover {
    color: #018ABE;
    background-color: #D6EBEE;
}

.containerLinks section img {
    width: 80px;
    right: 10px;
    top: -20px;
}