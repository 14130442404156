#conteudoContienerCobranca {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1300px;
    margin: 120px auto 30px auto;
    animation: animacaoConteudo 0.5s;
}

@keyframes animacaoConteudo {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#atencimentoIcone {
    width: 200px;
}

#cabecalhoMensagemAtendimento {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

#cabecalhoMensagemAtendimento p {
    text-align: center;
    padding: 20px 30px;
    box-sizing: border-box;
}

#listaCobrancasContiener {
    margin-right: 3%;
    width: 100%;
    min-height: 200px;
    max-width: 1000px;
    background-color: #FFFFFF;
    border-radius: 10px;
    z-index: 10;
    min-width: 500px;
    box-sizing: border-box;
}

#listaCobrancasContiener h1 {
    padding: 20px 0 0 20px;
}

#tblistaCobrancas {
    width: calc(100% - 40px);
    margin: 20px;
    max-height: 500px;
    overflow-y: auto;
    border-collapse: collapse;
}

#tblistaCobrancas td, th {
    padding: 0 10px;
}

#tblistaCobrancas tbody tr {
    border-bottom: #D6EBEE solid 1px;
}

#tblistaCobrancas thead {
    height: 30px;
    border-bottom: #018ABE solid 1px;
}

#tblistaCobrancas tbody tr {
    height: 45px;
    transition: all 300ms ease-in-out;
}

#tblistaCobrancas tbody tr:hover {
    background-color: #D6EBEE;
}

.pagarPix {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.pagarPix img:hover {
    cursor: pointer;
    transform: scale(115%);
}

#semCobranca {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    animation: animacaoConteudo 1s ease-in-out;
}

#semCobranca img {
    width: 100px;
}

#semCobranca p {
    text-align: center;
    margin: 20px 0;
}

.situacaoCobranca {
    width: max-content;
    min-width: 200px;
}

/* RESPONSIVIDADE DA PÁGINA */

@media (max-width: 1000px) {

    #conteudoContienerCobranca {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #cabecalhoMensagemAtendimento p {
        padding: 20px;
    }

    #conteudoContiener {
        font-size: 1.2rem;
        margin: 0;
    }

    #cabecalhoMensagemAtendimento {
        width: 100%;
    }

    #listaCobrancasContiener {
        margin: 20px;
        min-width: 90%;
        width: 100%;
        overflow-x: scroll;
    }

    #listaCobrancasContiener h1 {
        text-align: center;
        padding: 20px 0;
    }

    #tblistaCobrancas {
        width: 100%;
        margin: 0;
        font-size: 0.7rem;
    }

    .situacaoCobranca {
        display: none;
    }
    
}

