.alternativasContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
}

.alternativa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
