header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px;
    z-index: 100;
    display: flex;
    align-items: center;
    background-color: #001B48;
    box-shadow: 0 0 0 rgba(154,160,185,.05), 0 0px 50px rgba(166,173,201,0.3);
}

#contienerCabecalho {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

#menuOpcoes {
    display:  none;
}

#logoHg {
    width: clamp(100px, 150px, 200px);
    cursor: pointer;
}

#contienerCabecalho section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#contienerCabecalho nav {
    display: flex;
    gap: 35px;
}

/* RESPONSIVIDADE DO COMPONETE*/

@media (max-width: 1000px) {

    header {
        height: 110px;
    }

    #contienerCabecalho nav {
        width: 100%;
        flex-direction: column;
    }

    #menuOpcoes {
        display:  block;
        cursor: pointer;
        padding: 5px 0 5px 0;
        margin: 5px 0 5px 0;
        animation: exibirMenu 300ms ease-in-out;
    }

    .exibirSubMenuOpcoes {
        position: absolute;
        top: 100px;
        right: 0;
        padding: 20px;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background: linear-gradient(90deg, #001B48 50%, rgba(151, 202, 216, 0.9), 100%);
        backdrop-filter: blur(5px);
        text-align: center;
        z-index: 30;
        animation: exibirMenu 300ms ease-in-out;
    }

    @keyframes exibirMenu {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 100%;
        }
        
    }
    
    .ocultarSubMenuOpcoes {
        display: none;
    }

    #opcoesMenu {
        color: #001B48;
    }


}