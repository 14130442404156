#containerDepoimento {
    width: 100%;
    height: max-content;
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 200%;
    padding: 30px 0;
    animation: animacaoHome 300ms ease-in-out;
}

@keyframes animacaoHome {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

#depoimentoTitulo {
    margin: 0 auto;
    max-width: 1300px;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 30px 40px;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0 0 0 rgba(154,160,185,.05), 0 0px 50px rgba(166,173,201,0.3);
}

#depoimentosClientesHg {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 30px auto;
    height: 400px;
    max-width: 1300px;
}


iframe {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(1, 138, 190, 0.5);
}

@media (max-width: 1000px) {

    #paginaHome {
        padding: 10px;
    }

    #paginaHome section {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    #apresentacaoServicosCabelaho {
        padding: 30px 10px;
    }

    #apresentacaoServicosCabelaho p {
        font-size: 1rem;
    }
    
    #apresentacaoServicos {
        width: 30px;
    }

    #servicosContiener {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        margin: 60px auto;
    }

    #servicoGestaoFinanceira, #servicoLinkPro, #servicoBpo, #servicoGesttor {
        width: 80px;
        margin: 0 20px 0px 0;
    }

    #lancamentoGesttor {
        font-size: 0.5rem;
        padding: 3px 10px;
        margin-top: -20px;
        left: 10px;
    }

    #videoDepoimentoClientesHg {
        height: 210px;
    }

    #textoChamadaAcao {
        margin-bottom: 20px;
    }

    #textoChamadaAcao p {
        margin: 10px 0;
        padding: 10px;
    }

    #servicosContiener section {
        display: block;
        margin-top: 0;
    }

    #diagnosticoGratuito b {
        order: 1;
        padding: 10px 0px;
    }

    #diagnosticoGratuito p {
        order: 2;
    }

    #agendarDiagnostico  {
        order: 3;
    }
}