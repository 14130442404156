.btnCadastroLogin, .recuperarSenhaContainer {
    cursor: pointer;
    transition: all 300ms;
}

.containerPolicitaTermo {
    margin: 10px 0;
    padding: 10px 0;
    text-align: justify;
}

.containerPolicitaTermo a {
    text-decoration: none;
    font-weight: bold;
    color: #000;
    transition: all 300ms;
}

.btnCadastroLogin:hover, .containerPolicitaTermo a:hover, .recuperarSenhaContainer p:hover {
    color: #FF6B6C;
}