.container {
  display: flex;
  height: 100%;
}

.container-modal-vaga {
  margin: auto;
  box-sizing: border-box;
  max-height: 100dvh;
  max-width: 1100px;
  overflow: auto;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.resultado-nao-encontrado {
  font-size: 2rem;
  color: #333;
  background-color: #ffcccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 90%;
  width: 400px;
}

.resultado-nao-encontrado span {
  font-weight: bold;
  color: #d9534f;
}

.cabecalho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #d1d5db;
  gap: 10px;
}

.informacoes-vaga {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logo-empresa {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.titulo-vaga {
  font-size: 1.25rem;
  font-weight: bold;
  color: #000000;
}

.tags-vaga {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0.5rem;
}

.tag {
  padding: 4px 12px;
  background-color: #e5e7eb;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #000000;
}

.btn-inscrever:hover {
  background-color: #000000;
}

.botao-voltar {
  padding: 8px 16px;
}

.botao-detalhes {
  background-color: #f0f0f0;
  border: 1px solid #001b48;
  color: #000000;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
}

.botao-detalhes:hover {
  background-color: #e1e1e1;
}

.conteudo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  padding: 24px;
}

.subtitulo {
  font-size: 1.125rem;
  font-weight: bold;
}

.descricao {
  font-size: 0.875rem;
  color: #000000;
}

.lista-atribuicoes {
  margin: 0;
  padding-left: 24px;
  color: #000000;
  list-style-type: disc;
}

.rodape {
  background-color: #f3f4f6;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.container-principal {
  display: flex;
  margin-top: 120px;
  justify-content: center;
}

.filtros {
  background-color: #ffffff;
  padding: 1.5rem;
  margin: 0px 10px;
  border-radius: 4px;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}

.opcoes-filtro {
  display: flex;
  flex-direction: column;
}

.item-filtro {
  position: relative;
}

.selecao-filtro {
  width: 250px;
  background-color: #f0f0f0;
  color: #1e2a37;
  margin-bottom: 1rem;
  padding: 10px 0px;
}

.lista-vagas {
  background-color: #fafafa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1500px;
}

.item-vaga {
  display: flex;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  align-items: center;
}

.detalhes-vaga {
  flex-grow: 1;
  padding-left: 1rem;
}

.nome-empresa,
.localizacao {
  font-size: 0.875rem;
  color: #6c757d;
}

.tag-vaga {
  background-color: #e1e1e1;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
}

.acoes-vaga {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.salario {
  font-size: 1rem;
  color: #1e2a37;
  font-weight: bold;
}

.botoes-acoes {
  display: flex;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .conteudo {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .cabecalho {
    flex-direction: column;
    align-items: flex-start;
  }

  .tags-vaga {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 8px;
  }

  .container-principal {
    flex-direction: column;
  }

  .opcoes-filtro {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 10px;
  }

  .logo-empresa {
    width: 60px;
    height: 60px;
  }

  .titulo-vaga {
    font-size: 1rem;
  }

  .descricao,
  .lista-atribuicoes,
  .nome-empresa,
  .localizacao {
    font-size: 0.75rem;
  }

  .filtros {
    width: auto;
    margin: 0;
    max-height: none;
    align-items: center;
  }

  .lista-vagas {
    min-width: 100%;
  }

  .item-vaga {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .detalhes-vaga {
    padding-left: 0;
  }

  .acoes-vaga {
    align-items: flex-start;
  }

  .botoes-acoes {
    gap: 8px;
    flex-wrap: wrap;
  }
  .tag {
    font-size: 0.775rem;
  }
}

@media (max-width: 480px) {
  .opcoes-filtro {
    display: flex;
  }

  .cabecalho {
    padding: 12px;
  }

  .btn-inscrever {
    padding: 6px 10px;
  }

  .salario {
    font-size: 0.875rem;
  }

  .container {
    max-width: 100%;
  }
}
