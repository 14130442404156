@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@import url('https://fonts.googleapis.com/css2? família= Poppins:wght@400;500;700;900 & display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

@font-face {
    src: url(../assets/fonts/Panton-BlackCaps.otf);
    font-family: 'Panton-BlackCaps';
    font-weight: normal;
}

h1 {
    font-family: 'Panton-BlackCaps';
}

* {
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Poppins', sans-serif;
    background-color: #EAF1F2;
    font-size: clamp(12px, 14px, 16px);
}

p, input, button {
    font-family: 'Poppins', sans-serif;
    min-width: 220px;
}

input, select, textarea {
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    text-align: justify;
    border: 1px solid rgba(120,143,156, 0.4);
}

label {
    padding: 10px 0;
}

input:focus, select:focus, textarea:focus {
    border: 1px solid #78869C;
}

input::placeholder {
    color: #A1A1A1;
    font-size: 0.8rem;
}

input::selection {
    background: #393E41;
    color: #E2E3E8;
}

input:invalid, select:invalid, textarea:invalid {
    border: #FF6B6C solid 1px;
}

