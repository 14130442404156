#confirmacao_operacao {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100%;
    z-index: 20;
}

#confirmacao_dados_operacao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

#confirmacao_operacao_img {
    width: 100px;
    margin-bottom: 10px;
}