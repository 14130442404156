#div_carregando_circulo {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100%;
    z-index: 20;
}
#carregando_circulo {
    width: 15vh;
    height: 15vh;
    border: 8px solid #FFF;
    border-bottom-color: #261132;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}