.fundoModal {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 200;
}

.containerModalCentro {
    position: absolute;
    background-color: #EAF1F2;
    max-width: 900px;
    margin: auto;
    box-sizing: border-box;
    height: max-content;
    max-height: 100dvh;
    overflow: auto;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    animation: exibirModal 0.5s;
}

.containerModal {
    position: absolute;
    background-color: #EAF1F2;
    max-width: 1000px;
    min-width: 50%;
    margin-left: 10px;
    box-sizing: border-box;
    min-height: 100dvh;
    height: max-content;
    box-sizing: border-box;
    right: 0;
    top: 0;
    border-radius: 10px 0 0 10px;
    padding: 10px;
    animation: exibirModal 0.5s;
}

@keyframes exibirModal {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

.novoContainerTitulo {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 10px;
    border-bottom: #FF6B6C solid 1px;
}

.adicionarModalIcone {
    width: 30px;
}

.formModal {
    height: max-content;
    overflow-y: auto;
    padding: 10px;
}

.formModal::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
}

.formModal::-webkit-scrollbar-thumb {
    background-color: #02457A;
}

.formModal section {
    display: flex;
    align-items: flex-end;
    padding: 5px 0;
    gap: 10px;
}

.formModal span {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.formModal .botoesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 0 0 0;
    margin-top: 10px;
    border-top: #FF6B6C solid 1px;
}

.botoesContainer {
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    right: 20px;
    height: max-content;
    justify-content: flex-end;
    gap: 5px;
}

@media (max-width: 1000px) {

    .containerModal {
        min-width: 350px;
    }

    .containerModal {
        margin-top: 20px;
    }

    .formModal section {
        flex-direction: column;
    }

}